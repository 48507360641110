import { notify } from "@kyvg/vue3-notification";

import axios from "axios"

export default {
    namespaced: true,

    state: () => ({
        selectedStaff: null,
        selectedGroup: null,
        reportsTypes: null,
        reportData: null,
        reportReservedData: null,
        referenceProfile: null,
        referenceProfiles: [],
        expertReferenceProfiles: []
    }),

    mutations: {
        setSelectedStaff(state, data) {
            state.selectedStaff = data
        },
        setSelectedGroup(state, data) {
            state.selectedGroup = data
        },
        setReportsTypes(state, data) {
            state.reportsTypes = data
        },
        setReportData(state, data) {
            state.reportData = data
        },
        setReferenceProfile(state, data) {
            state.referenceProfile = data
        },
        setReferenceReport(state, data) {
            state.referenceReport = data
        },
        setReferenceProfiles(state, data) {
            state.referenceProfiles = data
        },
        setNewReferenceProfile(state, data) {
            state.referenceProfiles.push(data)
        },
        updateProfileReference(state, data) {
            state.referenceProfiles.forEach((profile) => {
                if (profile.id == data.id) {
                    profile = data
                }
            })
        },
        deleteProfileReference(state, id) {
            state.referenceProfiles = state.referenceProfiles.filter((profile) => {
                return profile.id != id
            });
        },
        setCalculateProfiles(state, data) {
			state.calculateProfilesList = data;
		},
        setReportReservedData(state, data) {
            state.reportReservedData = data
        },
        setExpertReferenceProfiles(state, data) {
            state.expertReferenceProfiles = data
        },
        setNewExpertReferenceProfile(state, data) {
            state.expertReferenceProfiles.push(data)
        },
        updateExpertReferenceProfile(state, data) {
            state.expertReferenceProfiles = state.expertReferenceProfiles.map((profile) =>
                profile.id === data.id ? data : profile
            )
        }
    },

    actions: {
        async getReportsTypes({ commit }) {
            await axios.get('/analytics/types')
                .then(({ data }) => {
                    commit("setReportsTypes", data)
                })
        },
        async getBaseReportData({ commit }, request) {
            await axios.post('/analytics/report/general_group_respondents', request)
                .then(({ data }) => {
                    commit("setReportData", data)
                })
        },
        async getGroupReportData({ commit }, request) {
            await axios.post('/analytics/report/general_group_comparison', request)
                .then(({ data }) => {
                    commit("setReportData", data)
                })
        },
        async getGeneralDymanicData({ commit }, request) {
            await axios.post('/analytics/report/general_group_time_differences', request)
                .then(({ data }) => {
                    commit("setReportData", data)
                })
        },
        async getReferenceData({ commit }, request) {
            await axios.post('/analytics/report/reference_profile', request)
                .then(({ data }) => {
                    commit("setReportData", data)
                })
        },
        async getReferenceUsersData({ commit }, request) {
            await axios.post('/analytics/report/reference_users', request)
                .then(({ data }) => {
                    commit("setReportData", data)
                })
        },
        async getRepeatedData({ commit }, request) {
            await axios.post('/analytics/report/general_group_respondents_repeated', request)
                .then(({ data }) => {
                    commit("setReportData", data)
                })
        },
        async getDifferencesSexData({ commit }, request) {
            await axios.post('/analytics/report/general_group_sex_differences', request)
                .then(({ data }) => {
                    commit("setReportData", data)
                })
        },
        async getDifferencesAgeData({ commit }, request) {
            await axios.post('/analytics/report/general_group_age_differences', request)
                .then(({ data }) => {
                    commit("setReportReservedData", data)
                })
        },
        async getReferenceProfiles({ commit }) {
            await axios.get('/reference_profiles')
                .then(({ data }) => {
                    commit("setReferenceProfiles", data)
                })
        },
        async getReferenceProfile({ commit }, id) {
            await axios.get(`/reference_profiles/${id}`)
                .then(({ data }) => {
                    commit("setReferenceProfile", data)
                })
        },

        async getReferenceReport({ commit }, id) {
            await axios.get(`reference_profiles/report/${id}`)
                .then(({ data }) => {
                    commit("setReferenceReport", data)
                })
        },
        
        async deleteReferenceProfile({ commit }, id) {
            await axios.delete(`/reference_profiles/${id}`)
                .then(() => {
                    commit("deleteProfileReference", id)
                    notify({
                        type: "success",
                        title: "Операция прошла успешно"
                    })
                })
        },
        // eslint-disable-next-line no-unused-vars
        async createReferenceProfile({ commit }, reference) {
            await axios.post('/reference_profiles', reference)
                .then(({ data }) => {
                    reference.expert ? commit("setNewExpertReferenceProfile", data) :
                        commit("setNewReferenceProfile", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })

                })
        },
        async updateReferenceProfile({ commit }, reference) {
            await axios.put(`/reference_profiles/${reference.id}`, reference)
                .then(({ data }) => {
                    commit("updateProfileReference", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },

        async calculateProfile({ commit }, request) {
            await axios.post('reference_profiles/calculate', request)
                .then(({ data }) => {
                    commit("setCalculateProfiles", data)
                })
        },

        async getSummaryTable({ commit }, request) {
            await axios.post('/analytics/report/passing_summary', request)
                .then(({ data }) => {
                    commit("setReportData", data)
                })
        },
        async getSummaryTableExcel({ commit }, request) {
            return await axios.post('/analytics/report/passing_summary/excel', request)
        },
        async getGeneralReportTableExcel({ commit }, request) {
            return await axios.post('/analytics/report/general_passing_report/excel', request)
        },
        async getGeneralReportTable({ commit }, request) {
            await axios.post('/analytics/report/general_passing_report', request)
                .then(({ data }) => {
                    commit("setReportData", data)
            })
        },
    }
}