export default {
	CURATORS: 'curators',
	MAIN: 'main',
	FAQ: 'faq',
	COMPANIES: 'companies',
	PARTNERS_LIST: 'partnersList',
	PARTNER: 'partner',
	EXPERTS: 'experts',
	REVIEWS: 'reviews',
	RELATIONS: 'relations',
	PERSONAL_LIST: 'personalList',
	COURSES: 'courses',
	SIMULATORS: 'simulators',
	PASSINGS: 'passings',
	COMPANY: 'company',
	COMPANY_LIST: 'companyList',
	STAFFS: 'staffs',
	GROUPS: 'groups',
	ANALYTICS: 'analytics',
	ORDERS: 'orders',
	ADMIN: 'superadmin',
	BEGIN_WORK: 'beginWork',
	REFERENCES: 'references',
	REFERENCESLIST: 'profiles',
	REFERENCESFILES: 'files',
	TRAJECTORIES: 'trajectories',
	STANDARD: 'standard',
	FULL: 'full',
	ALLEMPLOYEES: 'allEmployees',
	NEWGROUP: 'newGroup',
	GROUP: 'group',
	COURSE: 'course',
	NEWPRODUCT: 'newProduct'
}