import { computed } from "vue";
import { useStore } from "vuex"

export default function () {
    const store = useStore();
    const role = computed(() => store.state.profile.profile ? store.state.profile.profile.role : "private_person")

    const rights = {
        "superadmin": new Set([
            "relations", "orders",
            "references", "expertReferences", "createOrders", "approveOrders", "organizations", "companyEdit", "companyDistribution",
            "mainStatistic", "mainReports", "usersList", "allOrders", "partnersList",
            "companyList", "personalList", "superadmin", "downloadReports", "beginWork", "referencesPage", "deleteReferences", "addGroup", "getWelcomeImg",
            "notifications", "companyAnalyticsAccess"
        ]),
        "admin": new Set([
            "references",
            "companyEdit", "mainStatistic",
            "groupQuota", "orders", "curators", "videoInstruction",
            "companyList", "personalList", "downloadReports", "beginWork", "deleteReferences", "addGroup", "getWelcomeImg",
            "notifications", "groupReferenceProfiles"
        ]),
        "curator": new Set([
            "references", "mainReports",
            "companyList", "personalList", "downloadReports"
        ]),
        "staff": new Set([
            "mainReports", "personalList", "downloadReports", "staff"
        ]),
        "private_person": new Set([
            "mainReports", "orders", "personalList", "downloadReports", "private_person"
        ])
    }

    const getRight = (right) => {
        if (role.value)
            return rights[role.value].has(right)
        else return false
    }

    return {
        role,
        rights,
        getRight,
    }
}