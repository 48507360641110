import { createStore } from "vuex";
import axios from "axios";

import company from './company'
import users from './users'
import curator from './curator'
import group from './group'
import test from './test'
import profile from './profile'
import analytics from './analytics'
import relations from './relations'
import notifications from './notifications'
import partners from './partners'
import ui from './ui'
import references from "./references";

export default createStore({
  state: {
    width: 0,
    errors: null,
    user: null,
    redirectProcess: false,
    courseLink: null,
    simulatorsLink: null,
    companyTrap: false,
    showBack: true,
    summary: null,
    isGlobalLoad: null,
    beginProgress: null,
    isFinishedProgress: false,
    statusUserActive: false
  },
  mutations: {
    setWidth(state, data) {
      state.width = data;
    },
    setErrors(state, data) {
      state.errors = data
    },
    setUser(state, data) {
      state.user = data
    },
    setRedirectProcess(state, data) {
      state.redirectProcess = data
    },
    setCourseLink(state, data) {
      state.courseLink = data
    },
    setCompanyTrap(state, data) {
      state.companyTrap = data
    },
    setShowBack(state, data) {
      state.showBack = data
    },
    setSummary(state, data) {
      state.summary = data;
    },
    setIsGlobalLoad(state, data) {
      state.isGlobalLoad = data;
    },
    setSimulatorsLink(state, data) {
      state.simulatorsLink = data;
    },
    setBeginProgress(state, data) {
      state.beginProgress = data;
    },
    setIsFinishedProgress(state, data) {
      state.isFinishedProgress = data;
    },
    setStatusActiveUser(state, data) {
      state.statusUserActive = data;
    }
  },
  actions: {
    async getCurrentUser({ commit }) {
      await axios.get(`/user`)
        .then(({ data }) => {
          commit("setUser", data)
        })
    },
    async getCourseLink({ commit }) {
      await axios.get('/lms')
        .then(({ data }) => {
          commit("setCourseLink", data)
        })
    },
    async getIspringSimulators({ commit }) {
      await axios.get('/simulators')
        .then(({ data }) => {
          commit("setSimulatorsLink", data)
        })
    },
    async logout({ commit }) {
      commit("setUser", null)
      document.cookie = "ei-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.way2wei.space";
      localStorage.removeItem("prevToken");
      localStorage.removeItem("currentToken");
      window.location.replace(`${process.env.VUE_APP_AUTH}logout`)
    },
    async getSummary({ commit }) {
      await axios.get('/summary')
        .then(({ data }) => {
          commit("setSummary", data)
        })
    },
    async getBeginWork({ commit }) {
      await axios.get('/checkList/start_work')
        .then(({ data }) => {
          commit("setBeginProgress", data)
        })
    },
    async getIsFinishedProgress({ commit }) {
      await axios.get('/checkList/isAllCheckBoxesDone')
        .then(({ data }) => {
          commit("setIsFinishedProgress", data)
        })
    },
    async sendForm({ commit }, request) {
      await axios
          .post(process.env.VUE_APP_SITE_BACK + '/api/actions/help', request)
    },
    async getStatusActiveUser({ commit }, email) {
      await axios.get(process.env.VUE_APP_BACK + `/api/users/${email}/check/active`).then(({ data }) => {
        commit("setStatusActiveUser", data)
      })
    }
  },
  modules: {
    company, users, group, test, profile, analytics, relations, notifications, curator, ui, partners, references
  },
});
